/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

h1 {
  font-size: 50px;
}

h2 {
  font-size: 55px;
}

h3 {
  font-size: 30px;
}

h4 {
  font-size: 22px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 15px;
}

body {
  color: white;
  font-style: normal;
}
body.grey {
  background: #f4f4f4;
}

a {
  text-decoration: none;
  color: inherit;
}
a:hover {
  color: #000;
}

.normal_text {
  line-height: 20px;
}

.mt0 {
  margin-top: 0;
}

.mt20 {
  margin-top: 20px !important;
}

.mt40 {
  margin-top: 55px;
}

.mt80 {
  margin-top: 80px;
}

.mt100 {
  margin-top: 100px;
}

.mb0 {
  margin-bottom: 0;
}

.mb20 {
  margin-bottom: 20px;
}

.mb40 {
  margin-bottom: 55px;
}

.mb80 {
  margin-bottom: 80px;
}

.mb100 {
  margin-bottom: 100px;
}

.pb0 {
  padding-bottom: 0;
}

.p0 {
  padding: 0;
}

.align_right {
  text-align: right;
}

.height_full {
  height: 100%;
}

.green_text {
  color: #c7ff00;
}

.red_text {
  color: #ff6972;
}

.grey_text {
  color: #acacac;
}

.block {
  display: block;
}

.dragbtn,
button.dragbtn,
.cms_editor_btn.dragbtn {
  cursor: move;
}

.grid {
  display: grid;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
}
.grid.grid-margin-bottom {
  margin-bottom: 20px;
}
.grid.grid-cols-2 {
  grid-template-columns: repeat(2, 1fr);
}
.grid.grid-cols-3 {
  grid-template-columns: repeat(3, 1fr);
}
.grid.grid-cols-4 {
  grid-template-columns: repeat(4, 1fr);
}
.grid.grid-cols-5 {
  grid-template-columns: repeat(5, 1fr);
}
.grid.grid-cols-6 {
  grid-template-columns: repeat(6, 1fr);
}
.grid .col-auto {
  grid-column: auto;
}
.grid .col-span-1 {
  grid-column: span 1 / span 1;
}
.grid .col-span-2 {
  grid-column: span 2 / span 2;
}
.grid .col-span-3 {
  grid-column: span 3 / span 3;
}
.grid .col-span-4 {
  grid-column: span 4 / span 4;
}
.grid .col-span-5 {
  grid-column: span 5 / span 5;
}
.grid .col-span-6 {
  grid-column: span 6 / span 6;
}
.grid.form_grid .input_holder {
  margin-bottom: 0;
}
.grid.form_grid + .form_action_container {
  padding-top: 20px;
}
.grid.cms_grid {
  grid-template-columns: auto 255px;
  grid-column-gap: 55px;
}
.grid.grid-fluid {
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
}

.flex {
  display: flex;
}
.flex.justify {
  justify-content: space-between;
}
.flex.justify_end {
  justify-content: flex-end;
}
.flex.align_end {
  align-items: flex-end;
}
.flex.center {
  align-items: center;
}
.flex > * {
  margin-bottom: 0;
}

@media (max-width: 992px) {
  .grid.grid-cols-4,
  .grid.grid-cols-5,
  .grid.grid-cols-6 {
    grid-template-columns: repeat(2, 1fr);
  }
  .grid .col-span-2,
  .grid .col-span-2,
  .grid .col-span-3,
  .grid .col-span-4,
  .grid .col-span-5 {
    grid-column: initial;
  }
}

@media (max-width: 768px) {
  .grid.grid-cols-2,
  .grid.grid-cols-3,
  .grid.grid-cols-4,
  .grid.grid-cols-5,
  .grid.grid-cols-6 {
    grid-template-columns: repeat(1, 1fr);
  }
}

.btn,
.btn.disabled,
.btn:disabled {
  height: 55px;
  line-height: 55px;
  border: 1px solid #c7ff00;
  -webkit-border-radius: 7px;
  border-radius: 7px;
  font-size: 16px;
  font-family: "TT Hoves";
  font-style: normal;
  color: #fff;
  background: #c7ff00;
  text-align: center;
  padding: 0 28px;
  display: inline-block;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
  cursor: pointer;
  white-space: nowrap;
  text-transform: capitalize;
  -webkit-appearance: none;
  text-decoration: none;
  box-sizing: border-box;
}
.btn:hover,
.btn:focus,
.btn:active,
.btn.disabled:hover,
.btn.disabled:focus,
.btn.disabled:active,
.btn:disabled:hover,
.btn:disabled:focus,
.btn:disabled:active {
  background: #2fc673;
  border-color: #2fc673;
  color: #fff;
}
.btn.btn-secondary,
.btn.disabled.btn-secondary,
.btn:disabled.btn-secondary {
  background: transparent;
  color: #929292;
  border-color: #929292;
}
.btn.btn-secondary:hover,
.btn.disabled.btn-secondary:hover,
.btn:disabled.btn-secondary:hover {
  border-color: #acacac;
  color: #929292;
}
.btn.btn-tertiary,
.btn.btn-tertiary.active,
.btn.disabled.btn-tertiary,
.btn.disabled.btn-tertiary.active,
.btn:disabled.btn-tertiary,
.btn:disabled.btn-tertiary.active {
  background: transparent;
  color: #929292;
  border-color: #929292;
  height: 34px;
  line-height: 34px;
  font-size: 14px;
  padding: 0 10px;
  border: none;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.6px;
}
.btn.btn-tertiary:hover,
.btn.btn-tertiary.active:hover,
.btn.disabled.btn-tertiary:hover,
.btn.disabled.btn-tertiary.active:hover,
.btn:disabled.btn-tertiary:hover,
.btn:disabled.btn-tertiary.active:hover {
  color: #c7ff00;
  background: rgba(60, 209, 127, 0.1);
}
.btn.btn-tertiary:hover path,
.btn.btn-tertiary:hover line,
.btn.btn-tertiary.active:hover path,
.btn.btn-tertiary.active:hover line,
.btn.disabled.btn-tertiary:hover path,
.btn.disabled.btn-tertiary:hover line,
.btn.disabled.btn-tertiary.active:hover path,
.btn.disabled.btn-tertiary.active:hover line,
.btn:disabled.btn-tertiary:hover path,
.btn:disabled.btn-tertiary:hover line,
.btn:disabled.btn-tertiary.active:hover path,
.btn:disabled.btn-tertiary.active:hover line {
  stroke: #c7ff00;
}
.btn.btn-tertiary svg,
.btn.btn-tertiary.active svg,
.btn.disabled.btn-tertiary svg,
.btn.disabled.btn-tertiary.active svg,
.btn:disabled.btn-tertiary svg,
.btn:disabled.btn-tertiary.active svg {
  fill: #8e8e8e;
}
.btn.btn-purple,
.btn.disabled.btn-purple,
.btn:disabled.btn-purple {
  background-color: rgba(75, 66, 174, 0.1);
  color: #4b42ad;
  border-color: transparent;
  font-weight: 600;
}
.btn.btn-purple svg,
.btn.disabled.btn-purple svg,
.btn:disabled.btn-purple svg {
  fill: #4b42ad;
}
.btn.btn-purple:hover,
.btn.btn-purple:active,
.btn.disabled.btn-purple:hover,
.btn.disabled.btn-purple:active,
.btn:disabled.btn-purple:hover,
.btn:disabled.btn-purple:active {
  background-color: rgba(75, 66, 174, 0.2);
}
.btn.btn-delete,
.btn.disabled.btn-delete,
.btn:disabled.btn-delete {
  background: transparent;
  color: #ff6972;
  border-color: transparent;
  font-weight: 600;
  text-transform: uppercase;
  height: 34px;
  line-height: 34px;
  font-size: 14px;
  padding: 0 10px;
}
.btn.btn-delete svg,
.btn.disabled.btn-delete svg,
.btn:disabled.btn-delete svg {
  fill: #ff6972;
}
.btn.btn-delete:hover,
.btn.btn-delete:active,
.btn.btn-delete:not(.disabled):active,
.btn.disabled.btn-delete:hover,
.btn.disabled.btn-delete:active,
.btn.disabled.btn-delete:not(.disabled):active,
.btn:disabled.btn-delete:hover,
.btn:disabled.btn-delete:active,
.btn:disabled.btn-delete:not(.disabled):active {
  background-color: rgba(255, 105, 114, 0.1);
  border-color: transparent;
  color: #ff6972;
}
.btn.with_icon,
.btn.only_icon,
.btn.disabled.with_icon,
.btn.disabled.only_icon,
.btn:disabled.with_icon,
.btn:disabled.only_icon {
  display: flex;
  align-items: center;
}
.btn.with_icon svg,
.btn.only_icon svg,
.btn.disabled.with_icon svg,
.btn.disabled.only_icon svg,
.btn:disabled.with_icon svg,
.btn:disabled.only_icon svg {
  margin-right: 10px;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}
.btn.with_icon.btn-primary svg,
.btn.only_icon.btn-primary svg,
.btn.disabled.with_icon.btn-primary svg,
.btn.disabled.only_icon.btn-primary svg,
.btn:disabled.with_icon.btn-primary svg,
.btn:disabled.only_icon.btn-primary svg {
  fill: #fff;
}
.btn.with_icon.btn-secondary svg,
.btn.only_icon.btn-secondary svg,
.btn.disabled.with_icon.btn-secondary svg,
.btn.disabled.only_icon.btn-secondary svg,
.btn:disabled.with_icon.btn-secondary svg,
.btn:disabled.only_icon.btn-secondary svg {
  fill: #8e8e8e;
}
.btn.only_icon,
.btn.disabled.only_icon,
.btn:disabled.only_icon {
  display: inline-flex;
}
.btn.only_icon svg,
.btn.disabled.only_icon svg,
.btn:disabled.only_icon svg {
  margin-right: 0;
}
.btn.social-btn,
.btn.disabled.social-btn,
.btn:disabled.social-btn {
  display: flex;
  align-items: center;
  color: #333333;
  background: #fff;
  border: 1px solid #eaeaea;
  position: relative;
  justify-content: center;
}
.btn.social-btn svg,
.btn.disabled.social-btn svg,
.btn:disabled.social-btn svg {
  position: absolute;
  left: 15px;
  top: 50%;
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.btn.social-btn.btn-google,
.btn.disabled.social-btn.btn-google,
.btn:disabled.social-btn.btn-google {
  background: #f7f7f7;
}
.btn.social-btn:hover,
.btn.disabled.social-btn:hover,
.btn:disabled.social-btn:hover {
  background: #f1f0f0;
  border-color: #eaeaea;
}
.btn.disabled,
.btn:disabled,
.btn.disabled.disabled,
.btn.disabled:disabled,
.btn:disabled.disabled,
.btn:disabled:disabled {
  opacity: 0.6;
  pointer-events: none;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  /* background-color: #fff; */
  background: black;
  border: 1px solid rgba(41, 41, 44, 0.2);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}
.card.card_padded {
  padding: 32px;
}
.card.flat_bottom {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.card.flat_bottom + .tab_content .tab_pane > .card.flat_top {
  border-top: none;
}
.card.flat_top {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
.card.fullpage_card {
  width: 100%;
}
.card.padded {
  padding: 20px;
}
.card .card-header,
.card .table-header {
  border: none;
  border-bottom: 1px solid #29292c;
  background: transparent;
  min-height: 83px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
}
.card .card-header h3,
.card .table-header h3 {
  font-size: 22px;
  line-height: 1;
  font-weight: 500;
  margin-bottom: 0;
}
.card .card-header.no_border,
.card .table-header.no_border {
  border: none !important;
}
.card caption {
  background-image: none;
  background-color: #fff;
  border-color: #eaeaea;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  padding: 20px;
  caption-side: top;
  line-height: 42px;
  color: inherit;
}
.card caption h2,
.card caption h3 {
  line-height: 42px;
  height: 42px;
}
.card .card-footer {
  background-color: black;
  border-top: 1px solid #29292c;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  padding: 20px 30px;
}
.card .card-header,
.card .table-header,
.card caption {
  min-height: 83px;
  padding: 20px;
  padding-left: 32px;
}
.card .card-header h2,
.card .card-header h3,
.card .table-header h2,
.card .table-header h3,
.card caption h2,
.card caption h3 {
  margin: 0;
}
.card.closed .card-header,
.card.closed .table-header {
  border-bottom: none;
}
.card .card-body {
  padding: 20px 32px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  margin-bottom: 0;
  overflow-x: auto;
  flex: 1 1 auto;
}
.card .card-body.not-padded {
  padding: 0;
}
.card .card-body.no_vertical_padding {
  padding-top: 0;
  padding-bottom: 0;
}
.card .table {
  background-color: black;
  margin-bottom: 0;
  width: 100%;
  text-align: left;
}
.card .table th,
.card .table td {
  padding: 16px;
}
.card .table th:first-letter,
.card .table td:first-letter {
  text-transform: capitalize;
}
.card .table th .kmmrce-radio .radio_label,
.card .table th .kmmrce-radio label,
.card .table th .form-check .radio_label,
.card .table th .form-check label,
.card .table td .kmmrce-radio .radio_label,
.card .table td .kmmrce-radio label,
.card .table td .form-check .radio_label,
.card .table td .form-check label {
  display: inline-block;
}
.card .table.table-bordered {
  border: none;
}
.card .table.table-bordered tbody tr {
  border-bottom: 0.5px solid #29292c;
}
.card .table.table-bordered tbody tr td,
.card .table.table-bordered tbody tr th {
  border-bottom: none !important;
  font-size: 16px;
  line-height: 24px;
  word-wrap: break-word;
  border-collapse: collapse;
  box-sizing: border-box;
  position: relative;
  width: 100%;
  flex: 0 0 83.33333%;
  max-width: 83.33333%;
  padding: 1.25rem;
  color: white;
  font-weight: 400;
  border: none;
  vertical-align: middle;
  border-bottom: none !important;
  border-bottom: 0.5px solid #29292c;
  white-space: nowrap;
}
.card .table.table-bordered tbody tr td:first-child,
.card .table.table-bordered tbody tr th:first-child {
  padding-left: 32px;
}
.card .table.table-bordered tbody tr td:last-child,
.card .table.table-bordered tbody tr th:last-child {
  padding-right: 32px;
}
.card .table.table-bordered tbody tr td.value_td,
.card .table.table-bordered tbody tr th.value_td {
  white-space: nowrap;
  font-weight: 500;
}
.card .table.table-bordered tbody tr td {
  width: auto !important;
  padding: 1.25rem;
  line-height: 1;
  border: none;
  vertical-align: middle;
  border-bottom: none !important;
  color: white;
}
.card .table.table-bordered tbody tr td.empty_list_message {
  padding: 32px;
}
.card .table.table-bordered tbody tr th {
  font-weight: 500;
}
.card .table.table-bordered tbody tr:last-child {
  border-bottom: none !important;
}
.card .table.table-bordered tbody tr:hover {
  background-color: #000000;
}
.card .table thead th,
.card .table thead td {
  font-size: 16px;
  font-weight: 600;
  color: white;
  border: none;
  border-bottom: 0.5px solid #29292c;
  white-space: nowrap;
  vertical-align: middle;
}
.card .table thead th a,
.card .table thead td a {
  color: inherit;
  text-decoration: none;
  font-weight: 600;
}
.card .table thead th:first-child,
.card .table thead td:first-child {
  padding-left: 32px;
}
.card .table thead th:last-child,
.card .table thead td:last-child {
  padding-right: 32px;
}
.card .table thead th.value_td,
.card .table thead td.value_td {
  white-space: nowrap;
  font-weight: 500;
}
.card .table thead tr:last-child {
  border-bottom: none !important;
}
.card .table-content {
  -webkit-border-radius: 12px;
  border-radius: 12px;
}
.card .table-content .btn-tertiary {
  left: -12px;
  position: relative;
}
.card em {
  font-style: inherit;
  font-weight: 500;
}
.card span.true {
  width: 10px;
  height: 10px;
  background: #c7ff00;
  -webkit-border-radius: 100px;
  border-radius: 100px;
  display: block;
  margin: 0 auto;
  font-size: 0;
}
.card.fullbody_card .table {
  min-width: 700px;
}
.card.fullbody_card .table .select2-container {
  min-width: 170px;
}

.orderable a {
  position: relative;
  display: inline-block;
  min-height: 15px;
}
.orderable a::after {
  content: url("./assets/kmmrce-theme-assets/order-arrow.svg");
  border: 0;
  position: absolute;
  z-index: 2;
  right: -25px;
  top: -1px;
  height: 21px;
  -moz-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.orderable a span {
  display: none;
}

.orderable.asc a::after {
  -moz-transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}

.statbox {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  min-height: 130px;
}
.statbox .statbox_info {
  opacity: 0;
  position: relative;
  top: 10px;
  -webkit-transition: all 600ms ease;
  -moz-transition: all 600ms ease;
  -ms-transition: all 600ms ease;
  -o-transition: all 600ms ease;
  transition: all 600ms ease;
}
.statbox .statbox_info span {
  font-size: 14px;
}
.statbox .statbox_info span.green_text {
  margin-bottom: 5px;
}
.statbox .statbox_info.show {
  opacity: 1;
  top: 0;
}
.statbox h4 {
  margin: 0;
  font-size: 22px;
  line-height: 1;
  font-weight: 500;
}
.statbox h3 {
  margin-bottom: 0;
  font-size: 30px;
  font-weight: 500;
}

.btn-toolbar {
  position: relative;
}
.btn-toolbar .btn-toolbar-trigger {
  cursor: pointer;
  display: block;
  width: 15px;
  height: 15px;
  margin: 0 auto;
}
.btn-toolbar .btn-toolbar-trigger:after {
  content: url("./assets/kmmrce-theme-assets/three-dots.svg");
  display: block;
  width: 100%;
}
.btn-toolbar .btn-toolbar-trigger:hover:after {
  content: url("./assets/kmmrce-theme-assets/three-dots-hover.svg");
}
.btn-toolbar ul {
  display: none;
  z-index: -1;
  pointer-events: none;
  position: absolute;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 6px 20px #00000033;
  text-align: left;
  border-radius: 16px;
  background: black;
  padding: 10px 0;
  -webkit-transition: all 200ms ease;
  -moz-transition: all 200ms ease;
  -ms-transition: all 200ms ease;
  -o-transition: all 200ms ease;
  transition: all 200ms ease;
}
.btn-toolbar ul li a {
  color: #333333;
  text-decoration: none;
  padding: 0.5rem 1rem;
  display: block;
  padding-right: 50px;
}
.btn-toolbar ul li a {
  color: white;
}
.btn-toolbar ul li a:hover {
  color: #c7ff00;
}
.btn-toolbar.open .btn-toolbar-trigger:after {
  content: url("./assets/kmmrce-theme-assets/three-dots-hover.svg");
}
.btn-toolbar.open ul {
  display: flex;
  z-index: 10;
  pointer-events: initial;
}

.input_holder,
.control-group,
.form-group {
  position: relative;
  margin-bottom: 24px;
}
.input_holder input,
.control-group input,
.form-group input {
  -webkit-appearance: none;
}
.input_holder label,
.control-group label,
.form-group label {
  position: relative;
  display: block;
  line-height: 23px;
  margin-bottom: 10px;
}
.input_holder .input_wrapper,
.control-group .input_wrapper,
.form-group .input_wrapper {
  position: relative;
}
.input_holder label input,
.input_holder label textarea,
.control-group label input,
.control-group label textarea,
.form-group label input,
.form-group label textarea {
  margin-top: 10px;
}
.input_holder label button,
.control-group label button,
.form-group label button {
  position: absolute;
  right: 0;
  top: 50%;
  height: 34px;
  line-height: 32px;
}
.input_holder input[type="text"],
.input_holder input[type="number"],
.input_holder input[type="tel"],
.input_holder input[type="email"],
.input_holder input[type="password"],
.input_holder textarea,
.control-group input[type="text"],
.control-group input[type="number"],
.control-group input[type="tel"],
.control-group input[type="email"],
.control-group input[type="password"],
.control-group textarea,
.form-group input[type="text"],
.form-group input[type="number"],
.form-group input[type="tel"],
.form-group input[type="email"],
.form-group input[type="password"],
.form-group textarea {
  width: 100%;
  box-sizing: border-box;
  /* color: #26262f; */
  background: black;
  border: none;
  font-family: "TT Hoves";
  font-style: normal;
  text-transform: none;
  border: 1px solid #929292;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  height: 55px;
  line-height: 55px;
  box-sizing: border-box;
  font-size: 16px;
  padding: 0 15px;
  resize: none;
}
.input_holder input[type="text"]:focus,
.input_holder input[type="number"]:focus,
.input_holder input[type="tel"]:focus,
.input_holder input[type="email"]:focus,
.input_holder input[type="password"]:focus,
.input_holder textarea:focus,
.control-group input[type="text"]:focus,
.control-group input[type="number"]:focus,
.control-group input[type="tel"]:focus,
.control-group input[type="email"]:focus,
.control-group input[type="password"]:focus,
.control-group textarea:focus,
.form-group input[type="text"]:focus,
.form-group input[type="number"]:focus,
.form-group input[type="tel"]:focus,
.form-group input[type="email"]:focus,
.form-group input[type="password"]:focus,
.form-group textarea:focus {
  outline: 0;
  border-color: #d7d7da;
  box-shadow: none;
}
.input_holder input[type="text"]:disabled,
.input_holder input[type="number"]:disabled,
.input_holder input[type="tel"]:disabled,
.input_holder input[type="email"]:disabled,
.input_holder input[type="password"]:disabled,
.input_holder textarea:disabled,
.control-group input[type="text"]:disabled,
.control-group input[type="number"]:disabled,
.control-group input[type="tel"]:disabled,
.control-group input[type="email"]:disabled,
.control-group input[type="password"]:disabled,
.control-group textarea:disabled,
.form-group input[type="text"]:disabled,
.form-group input[type="number"]:disabled,
.form-group input[type="tel"]:disabled,
.form-group input[type="email"]:disabled,
.form-group input[type="password"]:disabled,
.form-group textarea:disabled {
  /* background: #fff; */
  text-overflow: ellipsis;
}
.input_holder textarea,
.control-group textarea,
.form-group textarea {
  min-width: 100%;
  max-width: 100%;
  min-height: 250px;
  max-height: 250px;
  letter-spacing: 0;
  line-height: 20px;
  padding-top: 20px;
}
.input_holder .input_holder_icon,
.control-group .input_holder_icon,
.form-group .input_holder_icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 16px;
}
.input_holder label + .input_holder_icon,
.control-group label + .input_holder_icon,
.form-group label + .input_holder_icon {
  top: calc(50% + 16px);
}
.input_holder.error input[type="text"],
.input_holder.error input[type="number"],
.input_holder.error input[type="tel"],
.input_holder.error input[type="email"],
.input_holder.error input[type="password"],
.input_holder.error textarea,
.control-group.error input[type="text"],
.control-group.error input[type="number"],
.control-group.error input[type="tel"],
.control-group.error input[type="email"],
.control-group.error input[type="password"],
.control-group.error textarea,
.form-group.error input[type="text"],
.form-group.error input[type="number"],
.form-group.error input[type="tel"],
.form-group.error input[type="email"],
.form-group.error input[type="password"],
.form-group.error textarea {
  border-color: #ffb2b2 !important;
}
.input_holder.error .error_message,
.control-group.error .error_message,
.form-group.error .error_message {
  display: block;
}
.input_holder .show_password,
.control-group .show_password,
.form-group .show_password {
  position: absolute;
  top: 0;
  right: 10px;
  top: 37px;
  width: 32px;
  background-position: left center;
  cursor: pointer;
}
.input_holder .show_password .state2,
.control-group .show_password .state2,
.form-group .show_password .state2 {
  display: none;
  height: 31px;
  width: 22px;
  margin: 0 auto;
}
.input_holder .show_password.visible_pass .state2,
.control-group .show_password.visible_pass .state2,
.form-group .show_password.visible_pass .state2 {
  display: block;
}
.input_holder .show_password.visible_pass .state1,
.control-group .show_password.visible_pass .state1,
.form-group .show_password.visible_pass .state1 {
  display: none;
}
.input_holder .chosen-container-single .chosen-single,
.control-group .chosen-container-single .chosen-single,
.form-group .chosen-container-single .chosen-single {
  background: none;
  height: 50px;
  border: none;
  border-bottom: 1px solid #acacac;
  box-shadow: none;
  -webkit-border-radius: 0px;
  border-radius: 0px;
  font-size: 22px;
  color: #26262f;
  background-size: 12px auto;
}
.input_holder .chosen-container-single .chosen-single span,
.control-group .chosen-container-single .chosen-single span,
.form-group .chosen-container-single .chosen-single span {
  line-height: 50px;
  display: block;
}
.input_holder .chosen-container-single .chosen-single .chosen-placeholder,
.control-group .chosen-container-single .chosen-single .chosen-placeholder,
.form-group .chosen-container-single .chosen-single .chosen-placeholder {
  display: none;
}
.input_holder .chosen-container-single .chosen-drop,
.control-group .chosen-container-single .chosen-drop,
.form-group .chosen-container-single .chosen-drop {
  margin-top: 4px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
  background-clip: padding-box;
  padding: 0;
  font-size: 16px;
  border: 1px solid #acacac;
  border-top: none;
  box-shadow: 0px -2px 16px rgba(0, 0, 0, 0.12);
  top: auto;
  bottom: 100%;
}
.input_holder .chosen-container .chosen-results,
.control-group .chosen-container .chosen-results,
.form-group .chosen-container .chosen-results {
  padding: 0;
  margin: 0;
  max-height: 120px;
}
.input_holder .chosen-container-single .chosen-drop,
.control-group .chosen-container-single .chosen-drop,
.form-group .chosen-container-single .chosen-drop {
  border-color: #eaeaea;
  border-top: none;
  -webkit-border-radius: 7px;
  border-radius: 7px;
  overflow: hidden;
}
.input_holder .chosen-container-single .chosen-single span,
.control-group .chosen-container-single .chosen-single span,
.form-group .chosen-container-single .chosen-single span {
  line-height: 55px;
}

.control-group .kmmrce-radio {
  margin-bottom: 0;
}
.control-group .kmmrce-radio .radio_label {
  margin-bottom: 0;
}

.error_message {
  font-size: 12px;
  position: absolute;
  bottom: -17px;
  width: 100%;
  left: 0;
  color: #ff6262;
  display: none;
}

.kmmrce-select-container .kmmrce-select__input {
  height: 55px !important;
  box-sizing: border-box;
}

.kmmrce-select-container .kmmrce-select__input-container,
.kmmrce-select-container .kmmrce-select__value-container {
  margin: 0;
  padding-top: 0;
  padding-bottom: 0;
  color: white;
}

.kmmrce-select-container .kmmrce-select__control {
  border-color: #eaeaea;
  box-shadow: none;
  height: 55px;
  -webkit-border-radius: 7px;
  border-radius: 7px;
  background: black;
  border: 1px solid #929292;
}
.kmmrce-select-container .kmmrce-select__control .kmmrce-select__single-value {
  color: white;
  font-size: 16px;
}
.kmmrce-select-container .kmmrce-select__control:hover,
.kmmrce-select-container .kmmrce-select__control:focus {
  border-color: #acacac;
}
.kmmrce-select-container
  .kmmrce-select__control:hover
  .kmmrce-select__single-value,
.kmmrce-select-container
  .kmmrce-select__control:focus
  .kmmrce-select__single-value {
  color: #333333;
}
.kmmrce-select-container
  .kmmrce-select__control:hover
  .kmmrce-select__indicator
  svg
  path,
.kmmrce-select-container
  .kmmrce-select__control:focus
  .kmmrce-select__indicator
  svg
  path {
  fill: #333333;
}
.kmmrce-select-container .kmmrce-select__control .kmmrce-select__indicator {
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}
.kmmrce-select-container
  .kmmrce-select__control
  .kmmrce-select__indicator
  svg
  path {
  fill: #8e8e8e;
}
.kmmrce-select-container
  .kmmrce-select__control
  .kmmrce-select__indicator-separator {
  display: none;
}
.kmmrce-select-container
  .kmmrce-select__control.kmmrce-select__control--menu-is-open,
.kmmrce-select-container
  .kmmrce-select__control.kmmrce-select__control--focused {
  border-color: #acacac;
  box-shadow: none;
}
.kmmrce-select-container
  .kmmrce-select__control.kmmrce-select__control--menu-is-open
  .kmmrce-select__indicator,
.kmmrce-select-container
  .kmmrce-select__control.kmmrce-select__control--focused
  .kmmrce-select__indicator {
  -moz-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.kmmrce-select-container
  .kmmrce-select__control.kmmrce-select__control--menu-is-open
  .kmmrce-select__single-value,
.kmmrce-select-container
  .kmmrce-select__control.kmmrce-select__control--focused
  .kmmrce-select__single-value {
  color: #333333;
}
.kmmrce-select-container
  .kmmrce-select__control.kmmrce-select__control--menu-is-open
  .kmmrce-select__indicator
  svg
  path,
.kmmrce-select-container
  .kmmrce-select__control.kmmrce-select__control--focused
  .kmmrce-select__indicator
  svg
  path {
  fill: #333333;
}

.kmmrce-select__menu {
  -webkit-border-radius: 7px;
  border-radius: 7px;
  box-shadow: 0 0 0 1px #ebebeb;
  overflow: hidden;
  z-index: 2;
}

.kmmrce-select__menu-portal {
  margin: 0;
  padding: 0;
  color: black;
}
.kmmrce-select__menu-portal .kmmrce-select__option {
  height: 55px;
  line-height: 55px;
  box-sizing: border-box;
  padding-top: 0;
  padding-bottom: 0;
}
.kmmrce-select__menu-portal .kmmrce-select__option--is-selected {
  background: #ebebeb;
  color: #26262f !important;
}
.kmmrce-select__menu-portal .kmmrce-select__option--is-focused {
  color: #26262f;
  background: #ebebeb;
}

.kmmrce-select-container .select2-container,
.controls .select2-container,
.form-group .select2-container {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: white;
  border: 1px solid #eaeaea;
  cursor: default;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  min-height: 38px;
  outline: 0 !important;
  position: relative;
  -webkit-transition: all 100ms;
  transition: all 100ms;
  box-sizing: border-box;
  border-color: #eaeaea;
  box-shadow: none;
  -webkit-border-radius: 7px;
  border-radius: 7px;
}
.kmmrce-select-container .select2-container .selection,
.controls .select2-container .selection,
.form-group .select2-container .selection {
  height: 55px;
  box-sizing: border-box;
  width: 100%;
}
.kmmrce-select-container
  .select2-container
  .selection
  .select2-selection--single,
.kmmrce-select-container
  .select2-container
  .selection
  .select2-selection--multiple,
.controls .select2-container .selection .select2-selection--single,
.controls .select2-container .selection .select2-selection--multiple,
.form-group .select2-container .selection .select2-selection--single,
.form-group .select2-container .selection .select2-selection--multiple {
  border: none;
  -webkit-border-radius: 0px;
  border-radius: 0px;
  background: transparent;
  height: 100%;
}
.kmmrce-select-container
  .select2-container
  .selection
  .select2-selection__rendered,
.controls .select2-container .selection .select2-selection__rendered,
.form-group .select2-container .selection .select2-selection__rendered {
  height: 100%;
  line-height: 55px;
  color: #929292;
  font-size: 16px;
}
.kmmrce-select-container
  .select2-container
  .selection
  .select2-selection__arrow,
.controls .select2-container .selection .select2-selection__arrow,
.form-group .select2-container .selection .select2-selection__arrow {
  right: 0;
  top: 3px;
  width: 20px;
  height: 20px;
  padding: 8px;
  box-sizing: content-box;
}
.kmmrce-select-container
  .select2-container
  .selection
  .select2-selection__arrow::before,
.controls .select2-container .selection .select2-selection__arrow::before,
.form-group .select2-container .selection .select2-selection__arrow::before {
  content: url("./assets/kmmrce-theme-assets/arrow-down.svg");
  width: 100%;
  height: 100%;
  display: block;
  opacity: 0.3;
}
.kmmrce-select-container
  .select2-container
  .selection
  .select2-selection__arrow
  b,
.controls .select2-container .selection .select2-selection__arrow b,
.form-group .select2-container .selection .select2-selection__arrow b {
  display: none;
}
.kmmrce-select-container .select2-container:hover,
.kmmrce-select-container .select2-container :focus,
.kmmrce-select-container .select2-container.select2-container--open,
.controls .select2-container:hover,
.controls .select2-container :focus,
.controls .select2-container.select2-container--open,
.form-group .select2-container:hover,
.form-group .select2-container :focus,
.form-group .select2-container.select2-container--open {
  border-color: #acacac;
}
.kmmrce-select-container .select2-container:hover .select2-selection__rendered,
.kmmrce-select-container .select2-container :focus .select2-selection__rendered,
.kmmrce-select-container
  .select2-container.select2-container--open
  .select2-selection__rendered,
.controls .select2-container:hover .select2-selection__rendered,
.controls .select2-container :focus .select2-selection__rendered,
.controls
  .select2-container.select2-container--open
  .select2-selection__rendered,
.form-group .select2-container:hover .select2-selection__rendered,
.form-group .select2-container :focus .select2-selection__rendered,
.form-group
  .select2-container.select2-container--open
  .select2-selection__rendered {
  color: #333333;
}
.kmmrce-select-container
  .select2-container:hover
  .select2-selection__arrow::before,
.controls .select2-container:hover .select2-selection__arrow::before,
.form-group .select2-container:hover .select2-selection__arrow::before {
  opacity: 1;
}
.kmmrce-select-container
  .select2-container.select2-container--open
  .select2-selection__arrow,
.controls .select2-container.select2-container--open .select2-selection__arrow,
.form-group
  .select2-container.select2-container--open
  .select2-selection__arrow {
  -moz-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.kmmrce-select-container
  .select2-container.select2-container--open
  .select2-selection__arrow::before,
.controls
  .select2-container.select2-container--open
  .select2-selection__arrow::before,
.form-group
  .select2-container.select2-container--open
  .select2-selection__arrow::before {
  opacity: 1;
}

body .select2-dropdown,
body .select2-container--open .select2-dropdown {
  top: 0;
  background-color: white;
  margin-bottom: 8px;
  margin-top: 8px;
  position: absolute;
  width: 100%;
  z-index: 1;
  box-sizing: border-box;
  -webkit-border-radius: 7px;
  border-radius: 7px;
  box-shadow: 0 0 0 1px #ebebeb;
  overflow: hidden;
  border: none;
  z-index: 10;
}
body .select2-dropdown .select2-search--dropdown,
body .select2-container--open .select2-dropdown .select2-search--dropdown {
  padding: 10px;
}
body .select2-dropdown .select2-search--dropdown .select2-search__field,
body
  .select2-container--open
  .select2-dropdown
  .select2-search--dropdown
  .select2-search__field {
  -webkit-border-radius: 7px;
  border-radius: 7px;
}
body .select2-dropdown .select2-search--dropdown .select2-search__field:focus,
body
  .select2-container--open
  .select2-dropdown
  .select2-search--dropdown
  .select2-search__field:focus {
  outline: none;
  font-size: 14px;
  padding-left: 10px;
}
body .select2-dropdown .select2-results__option,
body .select2-container--open .select2-dropdown .select2-results__option {
  height: 55px;
  line-height: 55px;
  padding: 0 12px;
  font-size: 16px;
  box-sizing: border-box;
}
body .select2-dropdown .select2-results__option--highlighted[aria-selected],
body
  .select2-container--open
  .select2-dropdown
  .select2-results__option--highlighted[aria-selected] {
  background-color: #ebebeb;
  color: #26262f;
}
body .select2-dropdown .select2-results__option[aria-selected="true"],
body
  .select2-container--open
  .select2-dropdown
  .select2-results__option[aria-selected="true"] {
  background-color: #ebebeb;
  color: #26262f;
}

.form_action {
  margin-bottom: 24px;
}
.form_action h5 a {
  color: #c7ff00;
}

.form_title {
  margin-bottom: 55px;
}
.form_title h1,
.form_title h2,
.form_title h3 {
  margin-bottom: 15px;
}
.form_title .form_intro {
  font-size: 18px;
  line-height: 21px;
}

.kmmrce-radio,
.form-check {
  display: inline-block;
  position: relative;
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 20px;
  padding: 0;
}
.kmmrce-radio .radio_input,
.kmmrce-radio input[type="radio"],
.kmmrce-radio input[type="checkbox"],
.form-check .radio_input,
.form-check input[type="radio"],
.form-check input[type="checkbox"] {
  position: absolute;
  top: 0px;
  left: 0;
  width: 20px;
  height: 20px;
  opacity: 0;
  z-index: 0;
  margin: 0;
}
.kmmrce-radio .radio_label,
.kmmrce-radio label,
.form-check .radio_label,
.form-check label {
  display: block;
  padding: 0 0 0 31px;
  cursor: pointer;
  line-height: 20px;
  margin: 0;
}
.kmmrce-radio .radio_label:before,
.kmmrce-radio label:before,
.form-check .radio_label:before,
.form-check label:before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0;
  width: 18px;
  height: 18px;
  background-color: transparent;
  border: 1px solid #4b42ad;
  border-radius: 100%;
  -webkit-border-radius: 100%;
  z-index: 1;
  transition: border-color 0.28s cubic-bezier(0.4, 0, 0.2, 1);
  box-sizing: content-box;
}
.kmmrce-radio .radio_label:after,
.kmmrce-radio label:after,
.form-check .radio_label:after,
.form-check label:after {
  content: "";
  position: absolute;
  top: 6px;
  left: 5.5px;
  width: 10px;
  height: 8px;
  background-color: #4b42ad;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  z-index: 2;
  transform: scale(0, 0);
  transition: transform 0.28s cubic-bezier(0.4, 0, 0.2, 1);
  background: transparent url(./assets/kmmrce-theme-assets/check.svg) no-repeat
    center center;
}
.kmmrce-radio .radio_input:checked + .radio_label:before,
.kmmrce-radio input[type="radio"]:checked + label:before,
.kmmrce-radio input[type="checkbox"]:checked + label:before,
.form-check .radio_input:checked + .radio_label:before,
.form-check input[type="radio"]:checked + label:before,
.form-check input[type="checkbox"]:checked + label:before {
  border-color: #4b42ad;
  background: #4b42ad;
}
.kmmrce-radio .radio_input:checked + .radio_label:after,
.kmmrce-radio input[type="radio"]:checked + label:after,
.kmmrce-radio input[type="checkbox"]:checked + label:after,
.form-check .radio_input:checked + .radio_label:after,
.form-check input[type="radio"]:checked + label:after,
.form-check input[type="checkbox"]:checked + label:after {
  transform: scale(1, 1);
}
.kmmrce-radio input:disabled + .radio_label,
.kmmrce-radio input:disabled + label,
.form-check input:disabled + .radio_label,
.form-check input:disabled + label {
  opacity: 0.5;
}
.kmmrce-radio.checkbox .radio_label:before,
.form-check.checkbox .radio_label:before {
  -webkit-border-radius: 4px;
  border-radius: 4px;
}
.kmmrce-radio input[type="checkbox"] + label:before,
.form-check input[type="checkbox"] + label:before {
  -webkit-border-radius: 4px;
  border-radius: 4px;
}

.social_signup {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 20px;
}

.login_control {
  align-items: baseline;
}
.login_control .forgot_pass {
  font-size: 14px;
  color: #8a8a8f !important;
  cursor: pointer;
}
.login_control .forgot_pass:hover {
  color: #c7ff00 !important;
}

.table_btns .btn {
  margin-right: 15px;
}
.table_btns .btn:last-child {
  margin-right: 0;
}

.custom_file_input {
  position: relative;
}
.custom_file_input .image-input.override {
  width: 100%;
}
.custom_file_input .image-input.override label {
  position: static;
  width: 100%;
  height: 100%;
}
.custom_file_input .image-input.override label span {
  color: #acacac;
  font-size: 12px;
}
.custom_file_input .image-input.override .input_image {
  border-color: #ebebeb !important;
}
.custom_file_input .custom_file_name {
  display: none;
  background: #f7f7f7;
  padding: 0 10px;
  color: #8a8a8f;
  font-size: 14px;
  width: auto;
  -webkit-border-radius: 7px;
  border-radius: 7px;
  overflow: hidden;
  justify-content: space-between;
  align-items: center;
  margin-top: 17px;
}
.custom_file_input .custom_file_name svg {
  height: 35px;
}
.custom_file_input .custom_file_name.show {
  display: flex;
}
.custom_file_input .custom_file_name .file-chosen-text {
  white-space: nowrap;
  display: block;
  text-overflow: ellipsis;
  max-width: 100%;
  overflow: hidden;
  line-height: initial;
}
.custom_file_input .custom_file_name .remove_file {
  cursor: pointer;
  margin-left: 10px;
}
.custom_file_input .custom_file_name .remove_file i {
  display: block;
  padding: 10px 0;
}
.custom_file_input .hidden_input {
  display: none;
}
.custom_file_input .upload-image .sortable-handle {
  min-width: 100%;
  width: 100%;
}
.custom_file_input .upload-image .form-group {
  min-height: inherit;
  height: auto;
}
.custom_file_input .upload-image .image-input {
  overflow: hidden;
  height: auto;
}
.custom_file_input .upload-image .image-input i {
  margin-bottom: 20px;
}
.custom_file_input .upload-image .image-input img {
  width: 100%;
  height: 100%;
  max-width: 100%;
  object-fit: cover;
  -webkit-border-radius: 7px;
  border-radius: 7px;
}
.custom_file_input .upload-image .override {
  width: 33.33%;
  min-width: 33.33%;
  position: relative;
  background: inherit;
  height: 200px !important;
}
.custom_file_input .upload-image .input_image,
.custom_file_input .upload-image .image-input {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.custom_file_input .upload-image .image-input {
  background-color: #fff;
  border: 1px dashed #acacac !important;
  -webkit-border-radius: 7px;
  border-radius: 7px;
  color: #333;
  display: flex;
  flex-direction: column;
  height: 200px;
  width: 100%;
  position: relative;
  z-index: 9999999;
}
.custom_file_input .upload-image .image-input svg {
  margin-bottom: 20px;
}
.custom_file_input .upload-image li {
  width: 24%;
  margin: 0 0.5% 2%;
}
.custom_file_input .upload-image li > div {
  background-color: transparent;
}
.custom_file_input .upload-image .btn {
  margin-bottom: 15px;
}
.custom_file_input .upload-image input[type="text"] {
  width: 100%;
  height: 42px;
  -webkit-border-radius: 7px;
  border-radius: 7px;
  border: 1px solid #acacac !important;
}
.custom_file_input .upload-image .input-field input {
  width: 100%;
}

.signup_block {
  max-width: 620px;
  margin: 0 auto;
}

.modal {
  position: fixed;
  background: #fff;
  z-index: 1100;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
  left: 50%;
  top: 50%;
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  top: 60%;
  box-shadow: 1px 2px 4px 1px #b5b5b5;
  height: auto;
  max-height: 90vh;
  width: 730px;
  max-width: 90%;
  -webkit-border-radius: 7px;
  border-radius: 7px;
  opacity: 0;
  pointer-events: none;
}
.modal.open,
.modal.show {
  visibility: visible;
  opacity: 1;
  pointer-events: initial;
  top: 50%;
}
.modal.modal_right {
  width: 1000px;
  height: 100vh;
  right: -100%;
  left: auto;
  top: 0;
  max-width: 50%;
  box-shadow: none;
  -webkit-transition: all 1.5s cubic-bezier(0.165, 0.84, 0.44, 1);
  -moz-transition: all 1.5s cubic-bezier(0.165, 0.84, 0.44, 1);
  -ms-transition: all 1.5s cubic-bezier(0.165, 0.84, 0.44, 1);
  -o-transition: all 1.5s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 1.5s cubic-bezier(0.165, 0.84, 0.44, 1);
  -moz-transform: none;
  -webkit-transform: none;
  -o-transform: none;
  -ms-transform: none;
  transform: none;
  max-height: inherit;
  -webkit-border-radius: 0px;
  border-radius: 0px;
}
.modal.modal_right.open {
  right: 0;
}
.modal.modal_right .modal_close {
  text-align: right;
}
.modal.modal_center .modal_content_holder {
  min-height: auto;
}
.modal .modal_content {
  padding: 0 55px 55px 55px;
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 1;
}
.modal .modal_header {
  display: flex;
  justify-content: flex-end;
  height: 35px;
  padding: 20px 55px 0 55px;
  font-style: normal;
  font-size: 16px;
}
.modal .modal_close {
  cursor: pointer;
}
.modal .modal_close:hover svg line {
  stroke: #8a8a8f;
}

#overlay {
  position: fixed;
  background: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 999;
  display: none;
}
#overlay.overlay_white {
  background: rgba(255, 255, 255, 0.8);
}

.sidebar {
  padding-top: 20px !important;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  padding: 0;
  overflow-y: auto;
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
}
.sidebar::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}
.sidebar .inner-menu {
  left: auto;
  right: 10px;
}
.sidebar .inner-menu.active {
  top: 100%;
}
.sidebar .sidebar-sticky {
  top: 48px;
  height: calc(100vh - 80px);
  padding-top: 78.5px;
}
.sidebar .nav_title {
  font-size: 13px;
  line-height: 1;
  font-weight: 600;
  padding: 20px 50px 20px;
  color: #333;
  letter-spacing: 0.8px;
  margin-bottom: 0;
}
.sidebar .navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  padding-bottom: 18px;
}
.sidebar li {
  padding: 10px 50px;
  position: relative;
}
.sidebar li svg,
.sidebar li i {
  margin-right: 20px;
  margin-left: 0;
}
.sidebar li svg path {
  stroke: #fff;
}
.sidebar li svg path:not(.highlight_stroke_only) {
  fill: #fff;
}
.sidebar li a {
  font-family: "TT Hoves";
  font-size: 18px;
  line-height: 1;
  font-weight: normal;
  color: white;
  text-decoration: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5rem 1rem;
  padding-right: 0;
  padding-left: 0;
  cursor: pointer;
}
.sidebar li {
  margin-left: 30px;
  margin-right: 30px;
  padding-left: 30px;
  padding-right: 30px;
}
.sidebar li.active {
  background: #000000;
  border-radius: 10px;
  border: 1px solid #929292;
}
.sidebar li.active svg path {
  stroke: #c7ff00;
}
.sidebar li.active svg path:not(.highlight_stroke_only) {
  fill: #c7ff00;
}
.sidebar li.active > a {
  font-weight: 500;
}

.burger_menu {
  display: block;
  height: 34px;
  width: 34px;
  border-radius: 100%;
  border: 1px solid #ebebeb;
  border-radius: 100%;
  display: none;
  position: fixed;
  left: 20px;
  top: 18px;
  z-index: 2001;
  background: #fff;
}
.burger_menu::before {
  content: "";
  width: 16px;
  height: 2px;
  background: #8e8e8e;
  border-radius: 20px;
  left: 50%;
  top: calc(50% - 5px);
  display: block;
  position: absolute;
  margin-left: -8px;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}
.burger_menu::after {
  content: "";
  width: 16px;
  height: 2px;
  background: #8e8e8e;
  border-radius: 20px;
  left: 50%;
  bottom: calc(50% - 5px);
  display: block;
  position: absolute;
  margin-left: -8px;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.open_menu .burger_menu::before {
  transform: rotate(45deg);
  top: calc(50% - 1px);
}

.open_menu .burger_menu::after {
  transform: rotate(-45deg);
  bottom: calc(50% - 1px);
}

.inner-menu {
  position: absolute;
  left: 60%;
  top: 15px;
  opacity: 0;
  cursor: default;
  box-shadow: 0px 6px 10px #00000033;
  text-align: left;
  -webkit-border-radius: 16px;
  border-radius: 16px;
  background: black;
  z-index: -1;
  pointer-events: none;
  width: max-content;
  padding: 10px 0;
  -webkit-transition: all 200ms ease;
  -moz-transition: all 200ms ease;
  -ms-transition: all 200ms ease;
  -o-transition: all 200ms ease;
  transition: all 200ms ease;
}
.inner-menu.active {
  opacity: 1;
  z-index: 10;
  pointer-events: initial;
  top: 0px;
}
.inner-menu .inner-menu-item {
  padding: 10px 20px;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
  color: #fff;
  display: block;
}
.inner-menu .inner-menu-item:hover {
  color: rgb(198, 255, 0) !important;
}

.inner-menu-toggle.open .menu-arrow {
  -moz-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.icon-component-short::before {
  content: url("./assets/kmmrce-theme-assets/component-icons/short-text.svg");
}

.icon-component-date::before {
  content: url("./assets/kmmrce-theme-assets/component-icons/date.svg");
}

.icon-component-html::before {
  content: url("./assets/kmmrce-theme-assets/component-icons/html.svg");
}

.icon-dashboard::before {
  content: url("./assets/kmmrce-theme-assets/icons/analytics.svg");
}

.icon-catalogue::before {
  content: url("./assets/kmmrce-theme-assets/icons/catalogue.svg");
}

.icon-fulfilment::before {
  content: url("./assets/kmmrce-theme-assets/icons/subscription.svg");
}

.icon-customers::before {
  content: url("./assets/kmmrce-theme-assets/icons/customers.svg");
}

.icon-content::before {
  content: url("./assets/kmmrce-theme-assets/icons/bulk.svg");
}

.icon-integrations::before {
  content: url("./assets/kmmrce-theme-assets/icons/payments.svg");
}

.icon-configuration::before {
  content: url("./assets/kmmrce-theme-assets/icons/transaction.svg");
}

.icon-reports::before {
  content: url("./assets/kmmrce-theme-assets/icons/orders.svg");
}

.nav-item.active .icon-dashboard::before {
  content: url("./assets/kmmrce-theme-assets/icons/analytics-active.svg");
}

.nav-item.active .icon-catalogue::before {
  content: url("./assets/kmmrce-theme-assets/icons/catalogue-active.svg");
}

.nav-item.active .icon-fulfilment::before {
  content: url("./assets/kmmrce-theme-assets/icons/subscription-active.svg");
}

.nav-item.active .icon-customers::before {
  content: url("./assets/kmmrce-theme-assets/icons/customers-active.svg");
}

.nav-item.active .icon-content::before {
  content: url("./assets/kmmrce-theme-assets/icons/bulk-active.svg");
}

.nav-item.active .icon-integrations::before {
  content: url("./assets/kmmrce-theme-assets/icons/payments-active.svg");
}

.nav-item.active .icon-configuration::before {
  content: url("./assets/kmmrce-theme-assets/icons/transaction-active.svg");
}

.nav-item.active .icon-reports::before {
  content: url("./assets/kmmrce-theme-assets/icons/orders-active.svg");
}

.icon-upload::before {
  content: url("./assets/kmmrce-theme-assets/icons/icon-upload.svg");
}

.icon-close-small::before {
  content: url("./assets/kmmrce-theme-assets/icons/icon-close-small.svg");
}

.icon-chevron::before {
  content: url("./assets/kmmrce-theme-assets/icons/chevron.svg");
}

.dashboard_container .main_page {
  width: 1800px;
  max-width: calc(100% - 15% - 20px);
  margin: 0 auto;
  margin-left: 15%;
}

.dashboard_container .sidebar,
.dashboard_container .kmmrce_logo {
  width: 15%;
}

.dashboard_container .kmmrce_logo {
  padding: 0 50px;
  box-sizing: border-box;
}

.inner_dashboard_container {
  width: 1300px;
  margin: 0 auto;
  overflow: hidden;
}

.dashboard_navbar {
  display: flex;
  align-items: center;
  height: 80px;
  position: sticky;
  top: 0;
  z-index: 1020;
}
.dashboard_navbar.hidden {
  pointer-events: none;
}

#top_nav_bar {
  height: 100%;
  transform: none;
  transition: transform 600ms cubic-bezier(0.535, 0, 0, 1);
  max-width: 1800px;
  margin: 0;
  flex-grow: 1;
  align-items: center;
}
#top_nav_bar ul,
#top_nav_bar li {
  list-style: none;
}
#top_nav_bar a {
  text-decoration: none;
}
#top_nav_bar .inner-menu {
  left: 0;
  top: 100%;
  width: 100%;
  min-width: 160px;
}
#top_nav_bar .inner-menu.active {
  top: calc(100% + 10px);
}
#top_nav_bar.scrollUp {
  transform: translateY(-150%);
}

.name_tag {
  background: #fff;
  font-size: 14px;
  padding: 4px 15px 4px 4px;
  -webkit-border-radius: 50px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  color: #333;
  cursor: default;
  margin: 0;
}
.name_tag figure {
  background: #4b42ad;
  font-size: 16px;
  height: 26px;
  width: 26px;
  line-height: 26px;
  border-radius: 100%;
  -webkit-border-radius: 100%;
  text-align: center;
  color: #fff;
  margin-right: 8px;
  overflow: hidden;
}
.name_tag figure img {
  max-width: 100%;
}
.name_tag .menu-arrow {
  position: relative;
  top: 1px;
  margin-left: 7px;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

a.name_tag {
  cursor: pointer;
  color: #929292;
}
a.name_tag:hover {
  color: #333333;
  background: #ededed;
}
a.name_tag:hover .menu-arrow path {
  fill: #333333;
  stroke: #333333;
}

.top_nav_btn {
  margin-right: 15px;
  width: 34px;
  height: 34px;
  background: #fafafa;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.top_nav_btn.has_notification:before {
  content: "";
  position: absolute;
  right: 7px;
  top: 4px;
  width: 9px;
  height: 9px;
  background: #ff6972;
  border: 1px solid #fafafa;
  border-radius: 100%;
}
.top_nav_btn:hover {
  background: #ededed;
}
.top_nav_btn:hover:before {
  border-color: #ededed;
}
.top_nav_btn:hover svg path {
  stroke: #333333;
  fill: #333333;
}

body .tox .tox-tbtn,
body .Draftail-ToolbarButton {
  background: #fff;
  margin: 0;
  margin-right: 10px;
}
body .tox .tox-tbtn .Draftail-ToolbarButton__label,
body .Draftail-ToolbarButton .Draftail-ToolbarButton__label {
  height: auto;
}

body .Draftail-Toolbar,
body .form-group .tox-toolbar__primary {
  padding: 8px 10px !important;
  background: #f6f6f6 !important;
}

body .Draftail-Toolbar,
body .Draftail-ToolbarGroup {
  display: flex;
}

body .Draftail-ToolbarButton {
  -webkit-border-radius: 3px;
  border-radius: 3px;
  padding: 0 12px;
  display: block;
  height: 34px;
}

body .Draftail-ToolbarButton__label {
  vertical-align: inherit;
  font-size: 14px;
}

body .Draftail-ToolbarButton--active,
body .Draftail-ToolbarButton:hover {
  background: #4b42ad;
  color: #fff;
}

body .Draftail-ToolbarGroup + .Draftail-ToolbarGroup::before {
  margin: 9px 12px;
  background: #e1e1e1;
}

body .tox-toolbar__group {
  border: none !important;
  padding: 0 !important;
}

body .tox .tox-tbtn:hover,
body .tox .tox-tbtn:active,
body .tox .tox-tbtn:focus,
body .tox .tox-tbtn.tox-tbtn--enabled,
body .tox .tox-tbtn.tox-tbtn--enabled:hover {
  background: #4b42ad;
  color: #fff !important;
}
body .tox .tox-tbtn:hover svg,
body .tox .tox-tbtn:active svg,
body .tox .tox-tbtn:focus svg,
body .tox .tox-tbtn.tox-tbtn--enabled svg,
body .tox .tox-tbtn.tox-tbtn--enabled:hover svg {
  fill: #fff;
}

body .tox .tox-tbtn:focus:not(.tox-tbtn--disabled) {
  color: #fff !important;
}
body .tox .tox-tbtn:focus:not(.tox-tbtn--disabled) svg {
  fill: #fff !important;
}

.line_chart_holder .apexcharts-xaxis-tick {
  opacity: 0;
}

.line_chart_holder .apexcharts-menu-icon {
  height: 20px;
}
.line_chart_holder .apexcharts-menu-icon svg {
  fill: none !important;
}

.line_chart_holder .apexcharts-toolbar {
  right: 15px !important;
}

.line_chart_holder .apexcharts-menu {
  box-shadow: 0px 6px 20px #00000033;
  text-align: left;
  -webkit-border-radius: 16px;
  border-radius: 16px;
  background: #fff;
}

.radial_chart_holder .apexcharts-legend-marker {
  margin-right: 10px;
}

.swiper-pagination-bullet-active {
  background-color: #c7ff00;
}

.swiper-slide .card {
  height: 100%;
}

.grid .swiper {
  width: 100%;
}

.components-menu li {
  margin-bottom: 10px;
}
.components-menu li:last-child {
  margin-bottom: 0;
}

.components-menu .cms_btn {
  cursor: pointer;
  display: flex;
  align-items: center;
  background: #fff;
  -webkit-border-radius: 8px;
  border-radius: 8px;
  color: #333;
  border: none;
  width: 100%;
  -webkit-appearance: none;
  padding: 12px;
  font-weight: 500;
  font-size: 16px;
  font-family: "TT Hoves";
  text-align: left;
}
.components-menu .cms_btn span {
  display: flex;
  font-size: 12px;
  color: #616161;
  font-weight: normal;
  line-height: 16px;
}
.components-menu .cms_btn:hover {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}

.cms_btn_icon {
  background: #f6f5fb;
  display: flex;
  width: 55px;
  height: 55px;
  margin-right: 12px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
}
.cms_btn_icon.large_cms_icon {
  width: 60px;
  height: 60px;
}
.cms_btn_icon.large_cms_icon img {
  width: 20px;
}

.tab_content .tab_pane {
  display: none;
}
.tab_content .tab_pane.active {
  display: block;
}

.tab_content + .side_pane {
  display: none;
}
.tab_content + .side_pane.active {
  display: block;
}

.tab_navigation {
  display: flex;
  flex-wrap: wrap;
}
.tab_navigation .tab_navigation_item {
  padding: 0 25px;
  display: flex;
  align-items: center;
}
.tab_navigation .tab_navigation_item a {
  display: block;
  padding: 34px 0;
  position: relative;
}
.tab_navigation .tab_navigation_item a::after {
  content: "";
  width: 100%;
  height: 2px;
  background: #c7ff00;
  position: absolute;
  left: 0;
  bottom: -1px;
  opacity: 0;
}
.tab_navigation .tab_navigation_item a.active {
  font-weight: 500;
  color: #26262f;
}
.tab_navigation .tab_navigation_item a.active::after {
  opacity: 1;
}
.tab_navigation .tab_navigation_item:first-child {
  padding-left: 0;
}

.vt-container .vt-card {
  display: flex;
  align-items: center;
  color: #fff;
  line-height: 1.3;
  will-change: transform;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1), 0 3px 3px rgba(0, 0, 0, 0.05);
  max-width: 350px;
  pointer-events: auto;
  padding: 8px 10px;
  border-radius: 8px;
  border-left: none !important;
}
.vt-container .vt-card p {
  font-size: 16px;
}
.vt-container .vt-card .text-group {
  display: flex;
  justify-content: center;
  margin: 4px 10px;
  color: inherit;
  flex: 1 1 auto;
}
.vt-container .vt-card svg path {
  fill: #fff;
}
.vt-container .vt-card.success {
  background: #63da99;
}
.vt-container .vt-card.error {
  background: #ff9999;
}
.vt-container .vt-card.error svg {
  position: relative;
  background: #ffffff;
  background: -webkit-radial-gradient(center, #ffffff, #bf101000);
  background: -moz-radial-gradient(center, #ffffff, #bf101000);
  background: radial-gradient(ellipse at center, #ffffff, #bf101000);
  border-radius: 100%;
}
.vt-container .vt-card.error svg path {
  fill: #ff4b4b;
}

.s-accordion-container,
.kmmrce-accordion__container {
  position: relative;
  width: 100%;
  height: auto;
}
.s-accordion-container.parent,
.kmmrce-accordion__container.parent {
  border-top: 2px solid #eaeaea;
  border-bottom: 2px solid #eaeaea;
}
.s-accordion-container .s-set,
.s-accordion-container .kmmrce-accordion,
.kmmrce-accordion__container .s-set,
.kmmrce-accordion__container .kmmrce-accordion {
  position: relative;
  width: 100%;
  height: auto;
  border-bottom: 1px solid #eaeaea;
}
.s-accordion-container .s-set:last-child,
.s-accordion-container .kmmrce-accordion:last-child,
.kmmrce-accordion__container .s-set:last-child,
.kmmrce-accordion__container .kmmrce-accordion:last-child {
  border-bottom: none;
}
.s-accordion-container .s-set .s-set-tab,
.s-accordion-container .s-set .kmmrce-accordion__tab,
.s-accordion-container .kmmrce-accordion .s-set-tab,
.s-accordion-container .kmmrce-accordion .kmmrce-accordion__tab,
.kmmrce-accordion__container .s-set .s-set-tab,
.kmmrce-accordion__container .s-set .kmmrce-accordion__tab,
.kmmrce-accordion__container .kmmrce-accordion .s-set-tab,
.kmmrce-accordion__container .kmmrce-accordion .kmmrce-accordion__tab {
  display: block;
  text-decoration: none;
  transition: all 0.2s linear;
  display: flex;
  align-items: center;
  padding: 20px 0px;
  position: relative;
  font-weight: 600;
  font-size: 18px;
  padding-left: 30px;
  color: inherit;
}
.s-accordion-container .s-set .s-set-tab .s-accordion-arrow,
.s-accordion-container .s-set .s-set-tab .kmmrce-accordion__arrow,
.s-accordion-container .s-set .kmmrce-accordion__tab .s-accordion-arrow,
.s-accordion-container .s-set .kmmrce-accordion__tab .kmmrce-accordion__arrow,
.s-accordion-container .kmmrce-accordion .s-set-tab .s-accordion-arrow,
.s-accordion-container .kmmrce-accordion .s-set-tab .kmmrce-accordion__arrow,
.s-accordion-container
  .kmmrce-accordion
  .kmmrce-accordion__tab
  .s-accordion-arrow,
.s-accordion-container
  .kmmrce-accordion
  .kmmrce-accordion__tab
  .kmmrce-accordion__arrow,
.kmmrce-accordion__container .s-set .s-set-tab .s-accordion-arrow,
.kmmrce-accordion__container .s-set .s-set-tab .kmmrce-accordion__arrow,
.kmmrce-accordion__container .s-set .kmmrce-accordion__tab .s-accordion-arrow,
.kmmrce-accordion__container
  .s-set
  .kmmrce-accordion__tab
  .kmmrce-accordion__arrow,
.kmmrce-accordion__container .kmmrce-accordion .s-set-tab .s-accordion-arrow,
.kmmrce-accordion__container
  .kmmrce-accordion
  .s-set-tab
  .kmmrce-accordion__arrow,
.kmmrce-accordion__container
  .kmmrce-accordion
  .kmmrce-accordion__tab
  .s-accordion-arrow,
.kmmrce-accordion__container
  .kmmrce-accordion
  .kmmrce-accordion__tab
  .kmmrce-accordion__arrow {
  display: flex;
  align-items: center;
  transform: rotateZ(0);
  transition: all 0.2s linear;
}
.s-accordion-container .s-set .s-set-tab span,
.s-accordion-container .s-set .kmmrce-accordion__tab span,
.s-accordion-container .kmmrce-accordion .s-set-tab span,
.s-accordion-container .kmmrce-accordion .kmmrce-accordion__tab span,
.kmmrce-accordion__container .s-set .s-set-tab span,
.kmmrce-accordion__container .s-set .kmmrce-accordion__tab span,
.kmmrce-accordion__container .kmmrce-accordion .s-set-tab span,
.kmmrce-accordion__container .kmmrce-accordion .kmmrce-accordion__tab span {
  width: 20px;
  height: 20px;
  display: block;
  border-radius: 100%;
  position: absolute;
  left: 20px;
  top: 50%;
  margin-top: -10px;
  opacity: 0;
  transition: all 0.2s linear;
}
.s-accordion-container .s-set .s-set-tab.active,
.s-accordion-container .s-set .kmmrce-accordion__tab.active,
.s-accordion-container .kmmrce-accordion .s-set-tab.active,
.s-accordion-container .kmmrce-accordion .kmmrce-accordion__tab.active,
.kmmrce-accordion__container .s-set .s-set-tab.active,
.kmmrce-accordion__container .s-set .kmmrce-accordion__tab.active,
.kmmrce-accordion__container .kmmrce-accordion .s-set-tab.active,
.kmmrce-accordion__container .kmmrce-accordion .kmmrce-accordion__tab.active {
  border-bottom: 0;
  border-bottom: 1px solid #eaeaea;
  padding-bottom: 16px;
  margin-bottom: 30px;
}
.s-accordion-container .s-set .s-set-tab.active .s-accordion-arrow,
.s-accordion-container .s-set .s-set-tab.active .kmmrce-accordion__arrow,
.s-accordion-container .s-set .kmmrce-accordion__tab.active .s-accordion-arrow,
.s-accordion-container
  .s-set
  .kmmrce-accordion__tab.active
  .kmmrce-accordion__arrow,
.s-accordion-container .kmmrce-accordion .s-set-tab.active .s-accordion-arrow,
.s-accordion-container
  .kmmrce-accordion
  .s-set-tab.active
  .kmmrce-accordion__arrow,
.s-accordion-container
  .kmmrce-accordion
  .kmmrce-accordion__tab.active
  .s-accordion-arrow,
.s-accordion-container
  .kmmrce-accordion
  .kmmrce-accordion__tab.active
  .kmmrce-accordion__arrow,
.kmmrce-accordion__container .s-set .s-set-tab.active .s-accordion-arrow,
.kmmrce-accordion__container .s-set .s-set-tab.active .kmmrce-accordion__arrow,
.kmmrce-accordion__container
  .s-set
  .kmmrce-accordion__tab.active
  .s-accordion-arrow,
.kmmrce-accordion__container
  .s-set
  .kmmrce-accordion__tab.active
  .kmmrce-accordion__arrow,
.kmmrce-accordion__container
  .kmmrce-accordion
  .s-set-tab.active
  .s-accordion-arrow,
.kmmrce-accordion__container
  .kmmrce-accordion
  .s-set-tab.active
  .kmmrce-accordion__arrow,
.kmmrce-accordion__container
  .kmmrce-accordion
  .kmmrce-accordion__tab.active
  .s-accordion-arrow,
.kmmrce-accordion__container
  .kmmrce-accordion
  .kmmrce-accordion__tab.active
  .kmmrce-accordion__arrow {
  transform: rotateZ(180deg);
}
.s-accordion-container .s-set .s-set-tab.active span,
.s-accordion-container .s-set .kmmrce-accordion__tab.active span,
.s-accordion-container .kmmrce-accordion .s-set-tab.active span,
.s-accordion-container .kmmrce-accordion .kmmrce-accordion__tab.active span,
.kmmrce-accordion__container .s-set .s-set-tab.active span,
.kmmrce-accordion__container .s-set .kmmrce-accordion__tab.active span,
.kmmrce-accordion__container .kmmrce-accordion .s-set-tab.active span,
.kmmrce-accordion__container
  .kmmrce-accordion
  .kmmrce-accordion__tab.active
  span {
  opacity: 1;
}
.s-accordion-container .s-set .s-set-content,
.s-accordion-container .s-set .kmmrce-accordion__content,
.s-accordion-container .kmmrce-accordion .s-set-content,
.s-accordion-container .kmmrce-accordion .kmmrce-accordion__content,
.kmmrce-accordion__container .s-set .s-set-content,
.kmmrce-accordion__container .s-set .kmmrce-accordion__content,
.kmmrce-accordion__container .kmmrce-accordion .s-set-content,
.kmmrce-accordion__container .kmmrce-accordion .kmmrce-accordion__content {
  max-height: 0;
  overflow: hidden;
  padding: 0 30px;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}
.s-accordion-container .s-set .s-set-content.show,
.s-accordion-container .s-set .kmmrce-accordion__content.show,
.s-accordion-container .kmmrce-accordion .s-set-content.show,
.s-accordion-container .kmmrce-accordion .kmmrce-accordion__content.show,
.kmmrce-accordion__container .s-set .s-set-content.show,
.kmmrce-accordion__container .s-set .kmmrce-accordion__content.show,
.kmmrce-accordion__container .kmmrce-accordion .s-set-content.show,
.kmmrce-accordion__container .kmmrce-accordion .kmmrce-accordion__content.show {
  max-height: inherit;
  overflow: visible;
  padding-bottom: 20px;
}
.s-accordion-container .s-set .s-accordion-title,
.s-accordion-container .s-set .kmmrce-accordion__title,
.s-accordion-container .kmmrce-accordion .s-accordion-title,
.s-accordion-container .kmmrce-accordion .kmmrce-accordion__title,
.kmmrce-accordion__container .s-set .s-accordion-title,
.kmmrce-accordion__container .s-set .kmmrce-accordion__title,
.kmmrce-accordion__container .kmmrce-accordion .s-accordion-title,
.kmmrce-accordion__container .kmmrce-accordion .kmmrce-accordion__title {
  display: flex;
  align-items: center;
  padding-left: 20px;
  font-size: 20px;
  font-weight: 500;
  width: 100%;
}
.s-accordion-container .s-set .s-accordion-title img,
.s-accordion-container .s-set .kmmrce-accordion__title img,
.s-accordion-container .kmmrce-accordion .s-accordion-title img,
.s-accordion-container .kmmrce-accordion .kmmrce-accordion__title img,
.kmmrce-accordion__container .s-set .s-accordion-title img,
.kmmrce-accordion__container .s-set .kmmrce-accordion__title img,
.kmmrce-accordion__container .kmmrce-accordion .s-accordion-title img,
.kmmrce-accordion__container .kmmrce-accordion .kmmrce-accordion__title img {
  margin-right: 30px;
  width: 42px;
}
.s-accordion-container .inner,
.kmmrce-accordion__container .inner {
  margin-bottom: 60px;
  border-top: 1px solid #cccccc;
  border-bottom: 1px solid #cccccc;
}
.s-accordion-container .inner .s-set,
.kmmrce-accordion__container .inner .s-set {
  border-color: #cccccc;
}
.s-accordion-container .inner .s-accordion-title,
.kmmrce-accordion__container .inner .s-accordion-title {
  font-size: 21px;
  font-weight: normal;
}

.kmmrce-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 25px;
  padding: 16px;
}
.kmmrce-pagination li {
  color: #c7ff00;
  border: 1px solid #c7ff00;
  border-radius: 7px;
}
.kmmrce-pagination li a {
  cursor: pointer;
  padding: 10px 20px;
  display: inline-block;
  transition: 0.3s;
}
.kmmrce-pagination .next,
.kmmrce-pagination .previous {
  background-color: #c7ff00;
  color: #fff;
  border-radius: 7px;
  font-size: 16px;
}
.kmmrce-pagination .next.disabled,
.kmmrce-pagination .previous.disabled {
  color: #000;
  background-color: #929292;
  border-color: #929292;
}
.kmmrce-pagination .btn-tertiary.active {
  color: #c7ff00;
  background: rgba(60, 209, 127, 0.1);
}

.kmmrce-note {
  background: #f7f7f7;
  color: #929292;
  padding: 16px;
  padding-bottom: 12px;
  width: 218px;
  align-self: flex-start;
  justify-self: flex-end;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

.kmmrce-note__title {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 8px;
}

.kmmrce-note__text {
  margin-bottom: 16px;
  font-size: 14px;
}
.kmmrce-note__text:last-child {
  margin-bottom: 0;
}

.kmmrce-note__list {
  font-size: 14px;
  list-style: disc;
  padding-left: 16px;
}

.kmmrce-note__list-item {
  margin-bottom: 4px;
}

.kmmrce-switch {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.kmmrce-switch__label {
  display: flex;
  align-items: center;
  margin-right: 50px;
}

.kmmrce-switch__icon {
  width: 30px;
  margin-right: 8px;
}

.kmmrce-switch__wrapper {
  position: relative;
  width: 24px;
}

.kmmrce-switch__input {
  width: 24px;
  height: 16px;
  position: relative;
  z-index: 9;
  opacity: 0;
  transition: 0.3s;
}
.kmmrce-switch__input:checked
  + .kmmrce-switch__handler-wrapper
  .kmmrce-switch__handler {
  transform: translateX(100%);
}

.kmmrce-switch__handler-wrapper {
  display: flex;
  position: absolute;
  top: 0;
  transition: 0.3s;
  margin: auto;
  width: 24px;
  height: 16px;
  background: rgba(60, 209, 127, 0.1);
  border-radius: 8px;
}

.kmmrce-switch__handler {
  width: 20px;
  height: 20px;
  background: #c7ff00;
  border-radius: 50%;
  margin-right: -6px;
  margin-top: -2px;
  margin-left: -6px;
  transform: translateX(-4px);
  transition: 0.3s;
}

.kmmrce-list__item {
  display: flex;
  margin-bottom: 32px;
}
.kmmrce-list__item:last-child {
  margin-bottom: 0;
}

.kmmrce-list__primary-text {
  font-size: 16px;
}

.kmmrce-list__metadata {
  margin-top: 8px;
  color: #929292;
  font-size: 14px;
}
.kmmrce-list__metadata.red_text {
  color: #ff6972;
}

.kmmrce-list__icon {
  margin-right: 8px;
}

footer {
  padding-top: 70px;
  background: #26262f;
  position: relative;
  color: #fff;
}
footer .left {
  width: 735px;
  max-width: 80%;
}
footer .footer_nav {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  font-size: 18px;
}
footer .footer_nav ul {
  padding-left: 20px;
  border-left: 1px solid #f2f2f5;
  min-height: 156px;
}
footer .footer_nav ul:first-child {
  border-left: none;
  padding-left: 0;
}
footer .footer_nav ul li {
  margin-bottom: 10px;
}
footer .footer_nav ul li a {
  color: inherit;
}
footer .footer_nav ul li a:hover {
  color: #c7ff00;
}
footer .bottom {
  height: 110px;
  font-size: 15px;
  align-items: flex-end;
  padding-bottom: 35px;
}

.input_holder .card-expiry-field {
  display: flex;
  align-items: center;
  position: relative;
  margin-top: 10px;
  border: 1px solid #eaeaea;
  -webkit-border-radius: 7px;
  border-radius: 7px;
}
.input_holder .card-expiry-field:after {
  content: "/";
  position: absolute;
  left: 50%;
  color: #eaeaea;
}
.input_holder .card-expiry-field input {
  border: none;
  margin: 0;
}

.react-datepicker {
  border: none !important;
  border-radius: 12px;
  box-shadow: 0 0 10px #70707080;
  padding: 16px;
}
.react-datepicker .react-datepicker__header {
  background-color: #fff;
  border-bottom: none;
}
.react-datepicker .react-datepicker__current-month {
  font-family: "TT Hoves";
  font-size: 25px;
  font-weight: 400;
  margin-bottom: 24px;
}
.react-datepicker .react-datepicker__day--outside-month {
  opacity: 0.2;
}
.react-datepicker
  .react-datepicker__week
  .react-datepicker__day--in-selecting-range,
.react-datepicker .react-datepicker__day--in-range {
  background-color: #f2f5f4;
  color: #333;
}
.react-datepicker .react-datepicker__day,
.react-datepicker .react-datepicker__day-name {
  font-family: "TT Hoves";
  margin: 0;
  border-radius: 0;
  font-size: 20px;
  /* padding: 10px; */
  margin-bottom: 10px;
  width: 2.5rem;
  line-height: 2.3rem;
  transition: 0.3s;
}
.react-datepicker .react-datepicker__day {
  border: 2px solid transparent;
}
.react-datepicker .react-datepicker__day-name {
  margin-bottom: 0;
  padding-bottom: 0;
}
.react-datepicker .react-datepicker__day--keyboard-selected {
  background: #fff;
}
.react-datepicker .react-datepicker__day--selected,
.react-datepicker
  .react-datepicker__day--selected.react-datepicker__day--selecting-range-start,
.react-datepicker .react-datepicker__day--range-end {
  background-color: rgb(198, 255, 0);
  border-radius: 50%;
  color: #fff;
}
.react-datepicker .react-datepicker__day--selected:hover,
.react-datepicker
  .react-datepicker__day--selected.react-datepicker__day--selecting-range-start:hover,
.react-datepicker
  .react-datepicker__day--range-end.react-datepicker__day--in-range:hover,
.react-datepicker
  .react-datepicker__day--range-start.react-datepicker__day--in-range:hover {
  background-color: #2b2664;
  border-radius: 50%;
  color: #fff;
}
.react-datepicker .react-datepicker__day--range-end {
  position: relative;
  z-index: 99;
  color: #fff;
}
.react-datepicker .react-datepicker__day--range-end:after {
  content: "";
  position: absolute;
  top: -2px;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: #f2f5f4;
  border: 2px solid #f2f5f4;
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
  z-index: -2;
}
.react-datepicker .react-datepicker__day--range-end::before {
  content: "";
  position: absolute;
  top: -2px;
  right: -2px;
  width: 100%;
  height: 100%;
  background-color: rgb(198, 255, 0);
  border-radius: 50%;
  z-index: -1;
  border: 2px solid rgb(198, 255, 0);
}
.react-datepicker .react-datepicker__day--range-start {
  position: relative;
  z-index: 99;
  color: #fff;
}
.react-datepicker .react-datepicker__day--range-start:after {
  content: "";
  position: absolute;
  top: -2px;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f2f5f4;
  border: 2px solid #f2f5f4;
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
  z-index: -2;
}
.react-datepicker .react-datepicker__day--range-start::before {
  content: "";
  position: absolute;
  top: -2px;
  right: -2px;
  width: 100%;
  height: 100%;
  background-color: rgb(198, 255, 0);
  border-radius: 50%;
  z-index: -1;
  border: 2px solid rgb(198, 255, 0);
}
.react-datepicker .react-datepicker__day:hover {
  background-color: #fff;
  border: 2px solid rgb(198, 255, 0);
  border-radius: 50%;
  color: #333;
}
.react-datepicker .react-datepicker__navigation-icon {
  display: none;
}
.react-datepicker .react-datepicker__navigation--previous {
  background-image: url("./assets/kmmrce-theme-assets/icons/chevron.svg");
  background-repeat: no-repeat;
  transform: rotate(180deg);
  top: 15px;
  left: 20px;
}

.react-datepicker__week .react-datepicker__day--in-range:hover {
  background-color: #e4eae8;
  border-radius: 0;
  color: #333;
  border: 2px solid #e4eae8;
}

.react-datepicker__triangle {
  display: none;
}

.react-datepicker .react-datepicker__navigation--next {
  background-image: url("./assets/kmmrce-theme-assets/icons/chevron.svg");
  background-repeat: no-repeat;
  position: absolute;
  top: 30px;
  right: 20px;
}

@media (max-height: 1020px) and (min-width: 1601px) {
  .sidebar {
    overflow-y: auto;
  }
  .inner-menu {
    left: auto;
    right: 10px;
  }
  .inner-menu.active {
    top: 100%;
  }
}

@media (max-width: 1600px) {
  .inner_dashboard_container {
    width: 100%;
  }
  .dashboard_container .grid.grid-cols-2 {
    grid-template-columns: repeat(1, 1fr);
  }
  .dashboard_container .grid.grid-cols-3 {
    grid-template-columns: repeat(2, 1fr);
  }
  .fixed-actions-group {
    right: auto;
    left: 5% !important;
    max-width: 90%;
    margin: 0 auto !important;
  }
  .sidebar {
    display: none;
  }
  .burger_menu {
    display: block;
  }
  .dashboard_container .main_page {
    max-width: 90%;
    margin-left: auto;
  }
  .dashboard_container .sidebar {
    position: fixed;
    left: -100%;
    top: 0;
    height: 100%;
    width: 370px;
    max-width: 90%;
    display: block;
    background: #14181a;
    z-index: 2000;
    -webkit-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    transition: all 300ms ease;
    overflow-y: scroll;
    overflow-x: visible;
  }
  .dashboard_container .sidebar .nav_title {
    padding-left: 30px;
  }
  .dashboard_container .sidebar li {
    padding-left: 30px;
  }
  .dashboard_container .sidebar .inner-menu {
    position: static;
    width: 100%;
    box-shadow: none;
    padding: 0 0 0 22px;
    max-height: 0;
  }
  .dashboard_container .sidebar .inner-menu .inner-menu-item {
    color: #fff;
  }
  .dashboard_container .sidebar .inner-menu.active {
    max-height: 1000px;
  }
  .burger_menu_overlay {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(20px);
    z-index: 1999;
    background: rgba(0, 0, 0, 0.1);
    display: none;
    -webkit-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    transition: all 300ms ease;
  }
  .open_menu .dashboard_container .sidebar {
    left: 0;
  }
  .open_menu .burger_menu_overlay {
    display: block;
  }
}

@media (max-width: 1600px) and (min-width: 992px) {
  .burger_menu {
    left: 20px;
    top: 23px;
  }
  .dashboard_container .kmmrce_logo {
    padding: 0 50px 0 85px;
  }
}

@media (max-width: 992px) {
  .burger_menu {
    top: 18px;
  }
  .dashboard_container .grid.grid-cols-3 {
    grid-template-columns: repeat(1, 1fr);
  }
  .dashboard_container .kmmrce_logo {
    padding: 0 20px;
  }
  .hide_mobile {
    display: none;
  }
  .name_tag.inner-menu-toggle {
    padding: 0;
  }
  .name_tag.inner-menu-toggle figure {
    width: 34px;
    height: 34px;
    line-height: 34px;
  }
  .name_tag.inner-menu-toggle svg {
    display: none;
  }
  .name_tag figure {
    margin-right: 0;
  }
  .site_name {
    display: none;
  }
  #top_nav_bar .inner-menu {
    right: 0;
    left: auto;
  }
  .line_chart_holder {
    padding-left: 20px;
  }
  .table-container {
    overflow: auto;
  }
  .dashboard_container {
    padding-top: 100px;
  }
  .dashboard_container .kmmrce_logo {
    position: absolute;
    left: 50%;
    -moz-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    padding: 0;
    display: flex;
    justify-content: center;
  }
  .dashboard_container .dashboard_navbar {
    position: fixed;
    width: 100%;
    top: 0;
    background: #fff;
    height: auto;
    padding: 18px 20px;
  }
  #top_nav_bar.scrollUp {
    -moz-transform: translateY(0);
    -webkit-transform: translateY(0);
    -o-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  .swiper-slide > .card_padded {
    padding-bottom: 50px;
  }
  .swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: 16px;
  }
  .page-header {
    margin-bottom: 30px;
  }
  .page-header h1 {
    font-size: 35px;
  }
  .page-header.flex.justify {
    flex-direction: column;
    align-items: flex-start;
  }
  .page-header.flex.justify h1 {
    margin-bottom: 15px;
  }
  .search_products {
    margin-top: 20px;
    width: 100%;
    position: relative;
    left: 0;
    top: 0;
  }
  .search_products .btn {
    margin-right: 0 !important;
  }
  .search_products .btn + .btn {
    margin-top: 15px;
  }
  .search_products.flex {
    position: static;
    flex-direction: column;
    align-items: stretch;
  }
  .search_products .form-inline {
    margin-bottom: 20px;
    flex-direction: column;
    align-items: stretch;
  }
  .search_products input[type="text"] {
    width: 100%;
  }
  .grid.cms_grid {
    grid-template-columns: 1fr;
  }
  .control-input .controls > a {
    word-break: break-word;
  }
  .input_holder,
  .control-group,
  .form-group {
    margin-bottom: 15px;
  }
  .form-group {
    width: 100%;
    margin-right: 0 !important;
  }
  .card .row {
    flex-direction: column;
  }
  .card .row .col-4.flex.justify_end.align_end {
    flex-direction: column;
    align-items: stretch;
  }
  .card .row .col-8 {
    margin-bottom: 15px;
  }
  .card .row * {
    max-width: 100%;
  }
  .card .row .form-inline {
    flex-direction: column;
    align-items: stretch;
  }
  .btn.with_icon {
    justify-content: center;
  }
  .card .table-content {
    overflow: auto;
  }
}
